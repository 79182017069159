import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from 'translations/en/index';
import it from 'translations/it/index';
import { languageOptions, appDefaultLanguage } from 'constants/generalConstants';
import { DETECTED_LANGUAGE, SELECTED_LANGUAGE } from 'constants/localStorageConstants';

//Gives us control to set a detected language and keep it separate from the selected language
const customDetector = {
	name: 'customDetector',
	lookup() {},
	cacheUserLanguage(lng) {
		//This is called on initial language detection and when the changeLanguage is called
		const browserLanguage = navigator.language;
		const storedDetectedLanguage = localStorage.getItem(DETECTED_LANGUAGE);
		const storedSelectedLanguage = localStorage.getItem(SELECTED_LANGUAGE);
		const newBrowserLanguageDetected = browserLanguage !== storedDetectedLanguage;

		if (!storedDetectedLanguage || newBrowserLanguageDetected) {
			//Save the language detected by the browser
			localStorage.setItem(DETECTED_LANGUAGE, browserLanguage);
		}
		if (!storedSelectedLanguage || newBrowserLanguageDetected) {
			//Set the selected language to the browser language if 1) it has not previously been set OR 2) there is a new browser language
			const appSupportsBrowserLanguage = languageOptions
				.map(o => o.value)
				.includes(browserLanguage);
			if (appSupportsBrowserLanguage) {
				localStorage.setItem(SELECTED_LANGUAGE, browserLanguage);
			} else {
				//Default to English
				localStorage.setItem(SELECTED_LANGUAGE, appDefaultLanguage);
			}
		} else {
			//Otherwise set the selected language to the language passed to changeLanguage (when the user manually changes it)
			localStorage.setItem(SELECTED_LANGUAGE, lng);
		}
	}
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customDetector);

const languageDetectionOptions = {
	caches: ['customDetector'],
	order: ['customDetector']
};

const options = {
	lng: localStorage.getItem(SELECTED_LANGUAGE),
	fallbackLng: appDefaultLanguage,
	ns: ['general', 'flights'],
	namespaceSeparator: ':', //Must be set in order to allow spaces in keys
	keySeparator: '.', //Must be set in order to allow spaces in keys
	defaultNS: 'general',
	// debug: true,
	resources: {
		en,
		it
	},
	detection: languageDetectionOptions,
	returnEmptyString: false
};

i18next
	.use(initReactI18next)
	.use(languageDetector)
	.init(options, () => {
		// if (err) return console.log('Error: ', err);
	});
