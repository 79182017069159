import { clearLocalStorageOnLogout } from 'actions/authentication';
import { COGNITO_CONFIG } from 'constants/localStorageConstants';
import { useLocation } from 'react-router-dom';
import { COGNITO_LOGOUT_URI } from 'constants/environmentVariables';
import { getQueryParamsFromLocation } from 'utils/urlHelpers';
import { useIntercom } from 'react-use-intercom';

const Logout = () => {
	const location = useLocation();
	const { hardShutdown } = useIntercom();

	const cognitoConfig = JSON.parse(localStorage.getItem(COGNITO_CONFIG));
	if (!cognitoConfig || !cognitoConfig?.clientId || !cognitoConfig?.host) {
		//There is not cognito config so a proper log out can not be achieved.
		//Clear local storage and return the user to login.
		hardShutdown();
		clearLocalStorageOnLogout();
		window.location.assign(`${origin}/login`);
		return; //Stop code execution here while waiting for above redirect to happen
	}

	const { clientId, host } = cognitoConfig;
	const queryParameters = getQueryParamsFromLocation(location);
	const { logout_uri } = queryParameters;
	hardShutdown();
	clearLocalStorageOnLogout();
	//Redirecting to these pages initiates the cognito logout
	if (logout_uri) {
		//Mobile flow
		window.location.assign(`${host}/logout?client_id=${clientId}&logout_uri=${logout_uri}`);
	} else {
		//Web flow
		window.location.assign(`${host}/logout?client_id=${clientId}&logout_uri=${COGNITO_LOGOUT_URI}`);
	}

	return null;
};

export default Logout;
