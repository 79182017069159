import { COGNITO_DOMAINS_MAP } from 'constants/environmentVariables';
import { endsWith } from 'lodash-es';
import { t } from 'i18next';

export const mapDomainToValues = domain => {
	const domains = JSON.parse(COGNITO_DOMAINS_MAP);
	const result = domains.find(object => {
		return object.idp_identifiers.includes(domain) ? object : undefined;
	});
	return result;
};

export const getDefaultDomainConfigObj = () => {
	const domains = JSON.parse(COGNITO_DOMAINS_MAP);
	const defaultConfig = domains.find(object => {
		return object.client_name === 'default' ? object : undefined;
	});
	return defaultConfig;
};

export const createMobileRedirectUrl = ({
	host,
	clientId,
	successRedirectURI,
	refreshToken,
	idToken,
	accessToken
}) => {
	const refreshEndpoint = `${host}/oauth2/token?grant_type=refresh_token&client_id=${clientId}&redirect_uri=${successRedirectURI}&refresh_token=${refreshToken}`;
	const logoutEndpoint = `${host}/logout?client_id=${clientId}`;
	const url = `${successRedirectURI}?id_token=${idToken}&refresh_token=${refreshToken}&access_token=${accessToken}&refresh_endpoint=${refreshEndpoint}&logout_endpoint=${encodeURIComponent(
		logoutEndpoint
	)}`;
	return url;
};

export const createAdminPageRedirect = ({
	host,
	clientId,
	successRedirectURI,
	idToken,
	refreshToken
}) => {
	const logoutEndpoint = `${host}/logout?client_id=${clientId}`;
	const url = `${successRedirectURI}?id_token=${idToken}&refresh_token=${refreshToken}&logout_endpoint=${encodeURIComponent(
		logoutEndpoint
	)}`;
	return url;
};

export const getDomainFromInput = value => {
	const splitValue = value.split('@');
	const domain = splitValue.length > 1 ? splitValue[1] : splitValue[0];
	return domain;
};

export const createAmplifyConfig = configObject => {
	const { clientId, userPoolId } = configObject;

	return {
		Auth: {
			// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
			// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

			// REQUIRED - Amazon Cognito Region
			region: 'us-east-2',

			// // OPTIONAL - Amazon Cognito Federated Identity Pool Region
			// // Required only if it's different from Amazon Cognito Region
			// identityPoolRegion: 'XX-XXXX-X',

			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: userPoolId,

			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolWebClientId: clientId,

			// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
			mandatorySignIn: false,

			// OPTIONAL - Configuration for cookie storage
			// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
			// cookieStorage: {
			//     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
			//     domain: 'http://localhost:3000',
			//     // OPTIONAL - Cookie path
			//     path: '/',
			//     // OPTIONAL - Cookie expiration in days
			//     expires: 365,
			//     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
			//     sameSite: "strict", // | "lax",
			//     // OPTIONAL - Cookie secure flag
			//     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
			//     secure: true
			// },

			// OPTIONAL - customized storage object
			// storage: MyStorage,

			// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
			authenticationFlowType: 'USER_PASSWORD_AUTH',

			// // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
			// clientMetadata: { myCustomKey: 'myCustomValue' },

			// OPTIONAL - Hosted UI configuration

			//This is not being used in our app
			oauth: {
				domain: 'auth.kittyspacehawk.com',
				scope: ['openid'], // ,[ 'phone', 'email', 'profile', 'aws.cognito.signin.user.admin'],
				redirectSignIn: 'http://localhost:3000/',
				redirectSignOut: 'http://localhost:3000/',
				responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
			}
		}
	};
};

export const parseLoginError = error => {
	switch (error) {
		case 'access_denied': {
			return `${t('Access is denied')}.`;
		}
		default: {
			return error;
		}
	}
};

export const convertCognitoError = error => {
	const { code, message } = error;
	return {
		response: {
			data: {
				errors: [{ detail: message, code }]
			}
		}
	};
};

export const checkRedirectURIAllowed = redirectURI => {
	if (redirectURI.startsWith('aircontrol:')) {
		//Mobile app link
		return true;
	}
	try {
		const allowedProtocols = ['http:', 'https:'];
		const allowedHostnameEndings = ['aloft.ai', 'aloft.rocks', 'localhost', 'aloft.link'];
		const { protocol, hostname } = new URL(redirectURI);
		const protocolAllowed = allowedProtocols.includes(protocol);
		//Check that one of the allowedHostnameEndings matches the ending of the hostname
		const hostnameAllowed = allowedHostnameEndings.some(allowed => endsWith(hostname, allowed));
		return protocolAllowed && hostnameAllowed;
	} catch {
		//redirectURI is not a valid URL
		return false;
	}
};
