export const LSAvailable = () => {
	try {
		localStorage.setItem('test', true);
		localStorage.removeItem('test');
		return true;
	} catch {
		window.alert('You must enable cookies in your browser to access this application.');
		return false;
	}
};
