import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	rolesPermAssignedList: []
};

const rolesSlice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		setRolesPermAssignedList(state, action) {
			state.rolesPermAssignedList = action.payload;
		},
		resetRolesSlice() {
			return initialState;
		}
	}
});

export const { setRolesPermAssignedList, resetRolesSlice } = rolesSlice.actions;

export default rolesSlice.reducer;

export const rolesPermAssignedListSelector = state => state.roles.rolesPermAssignedList;
