/**
 * remove value from Array if it exist and return Array without value
 * @function removeFromArray
 * @param {array}
 * @param {value}
 * @returns {Boolean}
 */
export const removeFromArray = (array, value) => {
	return array.filter(function (element) {
		return element !== value;
	});
};

/**
 * check if Array Length has properties
 * @function arrayHasProps
 * @param {array}
 * @returns {Boolean}
 */
export const arrayHasProps = array => {
	return array === undefined || array.length === 0 ? false : true;
};

/**
 * check if Object Length has properties
 * @function objectHasKeys
 * @param {object}
 * @returns {Boolean}
 */
export const objectHasKeys = object => {
	if (!object) {
		return false;
	}
	return Object.keys(object).length > 0;
};

/**
 * return String with Capitalize first latter
 * @function capitalizeEachWord
 * @param {string}
 * @returns {string}
 */
export const capitalizeEachWord = string_ => {
	if (!string_) return '';
	string_ = string_.split(' ').map(item => item.charAt(0).toUpperCase() + item.slice(1));
	return string_.join(' ');
};

/**
 * return array with Capitalize first latter
 * @function handleCapitalizeEachIdx
 * @param {array}
 * @returns {array}
 */
export const handleCapitalizeEachIdx = list => list.map(item => handleCapitalize(item));

/**
 * return string Capitalize first letter
 * @function handleCapitalize
 * @param {string}
 * @returns {string}
 */
// return duration from Start and End time
export const handleCapitalize = value => {
	return value && value.toString()
		? value && value.toString().charAt(0).toUpperCase() + value.toString().slice(1)
		: undefined;
};

/**
 * check if every item value is true, else return false
 * @function checkInputValid
 * @param {array of values}
 * @returns {Boolean}
 */
export const checkInputValid = array => {
	return array.every(Boolean);
};

/**
 * parase url parameter
 * @function parseUrlParam
 * @param {urlParam = string}
 * @returns {array of params}
 */
export const parseUrlParam = urlParameter => {
	urlParameter = urlParameter.replaceAll('?', '');
	let listParameter = {};

	function splitEaParameter() {
		return urlParameter.split('&');
	}

	function createKeyValue(list) {
		list = typeof list === 'object' ? list : [list];
		for (const element of list) {
			let item = element.split('=');
			listParameter[item[0]] = item[1];
		}
	}

	if (urlParameter.includes('&')) {
		createKeyValue(splitEaParameter(urlParameter));
	} else {
		createKeyValue(urlParameter);
	}
	return listParameter;
};

/**
 * find target in array of objects, return index of target in Array
 * @function findKeyInArrayOfObj
 * @param {array, integer, string}
 * @returns {integer}
 */
export const findKeyInArrayOfObj = (array, value, target) => {
	let index;
	for (const [index_, element] of array.entries()) {
		if (element[target].toString() === value.toString()) {
			index = index_;
		}
	}
	return index;
};

/**
 * handle route to link and update ActiveLink
 * @function handleRoute
 * @param {link = string, navigate}
 * @returns {}
 */
export const handleRoute = (link, navigate, e) => {
	if (e && e.metaKey) {
		window.open(`${window.location.origin}${link}`, '_blank');
	} else {
		navigate(link);
	}
};

export const mergeSortFn = (target, array, direction) => {
	function merge(left, right) {
		// compare the arrays item by item and return the concatenated result
		let result = [];
		let indexLeft = 0;
		let indexRight = 0;

		while (indexLeft < left.length && indexRight < right.length) {
			// convert to lowercase if string
			let leftSide =
				typeof left[indexLeft][target] === 'string'
					? left[indexLeft][target].toLowerCase()
					: left[indexLeft][target];
			let rightSide =
				typeof right[indexRight][target] === 'string'
					? right[indexRight][target].toLowerCase()
					: right[indexRight][target];

			if (direction) {
				if (leftSide < rightSide) {
					result.push(left[indexLeft]);
					indexLeft++;
				} else {
					result.push(right[indexRight]);
					indexRight++;
				}
			} else {
				if (leftSide > rightSide) {
					result.push(left[indexLeft]);
					indexLeft++;
				} else {
					result.push(right[indexRight]);
					indexRight++;
				}
			}
		}

		return result.concat(left.slice(indexLeft)).concat(right.slice(indexRight));
	}

	function mergeSort(array_) {
		if (array_.length === 1 || array_.length === 0) {
			// return once we hit an array with a single item or if there is nothing in the array
			return array_;
		}
		const middle = Math.floor(array_.length / 2); // get the middle item of the array rounded down
		const left = array_.slice(0, middle); // items on the left side
		const right = array_.slice(middle); // items on the right side
		return merge(mergeSort(left), mergeSort(right));
	}

	return mergeSort(array);
};

export const bytesToSize = bytes => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return 'n/a';
	const index = Number.parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
	if (index === 0) return `${bytes} ${sizes[index]}`;
	return `${(bytes / 1024 ** index).toFixed(2)} ${sizes[index]}`;
};

export const isValidCoordinates = (lat, long) => {
	const latitude = lat >= -90 && lat <= 90 ? true : false; // lat -90, 90
	const longitude = long >= -180 && long <= 180 ? true : false; // long -180, 180
	return [latitude, longitude].every(Boolean);
};

export const focusElementWithId = id => {
	const element = document.getElementById(id);
	if (element) {
		element.focus();
	}
};

export const clickButtonWithId = id => {
	const element = document.getElementById(id);
	if (element) {
		element.click();
	}
};

export const createDownloadLink = (file, data) => {
	const windowUrl = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = windowUrl;
	link.setAttribute('download', `${file.name}.${file.extension}`);
	document.body.append(link);
	return link;
};

export const createPreviewWindowUrl = ({ data, type }) => {
	return window.URL.createObjectURL(new Blob([data], { type }));
};

export const userFullNameWithComma = user => {
	if (!user || !user?.id) return '';
	// If user first and last name is single field
	if (user['0'] && user['0'].split(' ').length > 1) {
		return `${user['0'].split(' ').slice(-1)}, ${user['0'].split(' ').slice(0, 1)}`;
	}
	// If user first and last names are multiple fields
	return `${handleCapitalize(user.lastname || user.last_name || user['1'])}, ${handleCapitalize(
		user.firstname || user.first_name || user['0']
	)}`;
};

export const addNewKeyToObject = (object, key, target, state = false) => {
	object[target].map(item => {
		return (item[key] = state);
	});
	return object;
};

export const isPromise = object => {
	if (Promise && Promise.resolve) {
		return Promise.resolve(object) === object;
	} else {
		throw new Error('Promise not supported in your environment');
	}
};

/**
 * global pending request Object Varaible
 */
export let manageAbortContollers = {
	abortCurrentRequest: null
};

export let abortController;

export const resetWithNewAbortController = () => {
	abortController = new AbortController();
};

export const removeAbortController = () => {
	abortController = null;
};

/**
 * @description
 * if an existing request is active, it will Abort request before making this request.
 * table list request are done asynchronous, so requesting page 2 of a table will only happens when page 1 is finish.
 * @param {fn} abortRequest
 */
export const abortActiveRequest = canceler => {
	if (canceler) {
		canceler();
	}
};

// Split an array (array parameter) into even chunks (e parameter)
export const nthArray = (array, e) => {
	const array_ = [];
	const delta = Math.pow(2, Math.ceil(Math.log2(array.length / e)));
	for (let index = 0; index < array.length; index = index + delta) {
		array_.push(index);
	}
	return array_;
};

export const isJSON = value => {
	try {
		JSON.parse(value);
	} catch {
		return false;
	}
	return true;
};

export const parseJSONObjectKeys = object => {
	let newObject = {};
	for (const key of Object.keys(object)) {
		const value = object[key];
		let newValue = value;
		if (typeof value === 'string' && isJSON(value)) {
			//Get value from JSON string
			newValue = JSON.parse(value);
		}
		newObject[key] = newValue;
	}
	return newObject;
};

export const swapPlacesInArray = (array, index1, index2) => {
	const newArray = [...array];
	const temporary = newArray[index1];
	newArray[index1] = newArray[index2];
	newArray[index2] = temporary;
	return newArray;
};

export const arrayMove = (arr, oldIndex, newIndex) => {
	const itemToMove = arr[oldIndex];
	// //Remove at old index
	const removedOldIndex = arr.toSpliced(oldIndex, 1);
	// //Add at new index
	const addToNewIndex = removedOldIndex.toSpliced(newIndex, 0, itemToMove);
	return addToNewIndex;
};

export const convertMetersToFeet = meters => {
	return meters * 3.28084;
};

export const convertMetersToMiles = meters => {
	return meters * 0.000621371;
};
