import { $success, $white } from 'constants/styles';

export const defaultMapCenter = [-95.9325, 41.256_03]; //Center of USA

export const createMapDrawStyles = (fillColor = $success, lineColor = $white) => [
	// ACTIVE (being drawn)
	// line stroke
	{
		id: 'gl-draw-line',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': ['coalesce', ['get', 'user_lineColor'], lineColor],
			// 'line-dasharray': [0.2, 2],
			'line-width': 6
		}
	},
	// polygon fill
	{
		id: 'gl-draw-polygon-fill',
		type: 'fill',
		filter: ['all', ['==', '$type', 'Polygon']],
		paint: {
			'fill-color': ['coalesce', ['get', 'user_fillColor'], fillColor],
			'fill-outline-color': ['coalesce', ['get', 'user_lineColor'], lineColor],
			'fill-opacity': 1
		}
	},
	// polygon outline stroke
	// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
	{
		id: 'gl-draw-polygon-stroke-active',
		type: 'line',
		filter: ['all', ['==', '$type', 'Polygon']],
		paint: {
			'line-color': ['coalesce', ['get', 'user_lineColor'], lineColor],
			'line-opacity': 1,
			'line-width': 6
		},
		layout: {
			'line-join': 'round',
			'line-cap': 'round'
		}
	},
	// vertex point halos
	{
		id: 'gl-draw-polygon-and-line-vertex-halo-active',
		type: 'symbol',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
		paint: {
			'icon-opacity': 1
		},
		layout: {
			'icon-image': 'hospital-15',
			'icon-size': 1
		}
	},
	// vertex points
	{
		id: '',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 10, // size polygon draggable points
			'circle-color': ['coalesce', ['get', 'user_lineColor'], lineColor], // color polygon draggable points
			'circle-opacity': 0.75 // opacity polygon draggable points
		}
	},
	// midpoints
	{
		id: 'circle-midpoint',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 5, // size polygon draggable points
			'circle-color': '#000', // color polygon draggable points
			'circle-opacity': 0.25 // opacity polygon draggable points
		}
	},
	//Custom Colors - set by "user_fillColor" and "user_line_color"
	//These style the shapes after they are drawn
	//From: https://gist.github.com/dnseminara/0790e53cef9867e848e716937727ab18
	{
		id: 'gl-draw-polygon-user-color',
		type: 'fill',
		filter: [
			'all',
			['==', '$type', 'Polygon'],
			['has', 'user_fillColor'],
			['has', 'user_lineColor']
		],
		paint: {
			'fill-color': ['get', 'user_fillColor'],
			'fill-outline-color': ['get', 'user_lineColor'],
			'fill-opacity': 1
		}
	},
	{
		id: 'gl-draw-line-user-color',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_lineColor']],
		paint: {
			'line-color': ['get', 'user_lineColor'],
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-point-user-color',
		type: 'circle',
		filter: ['all', ['==', '$type', 'Point'], ['has', 'user_lineColor']],
		paint: {
			'circle-radius': 3,
			'circle-color': ['get', 'user_lineColor']
		}
	}
];

export const lineLayout = {
	'line-join': 'round',
	'line-cap': 'round'
};

export const linePaint = {
	'line-opacity': 1,
	'line-width': 6
};

export const fillPaint = {
	'fill-opacity': 1
};

/* 
Mapbox approximates 1 story to 3 meters. Reference: https://blog.mapbox.com/mapping-3d-building-features-in-openstreetmap-7685ee12712a  
Reference for building height breakdown: https://www.researchgate.net/figure/Classification-of-the-buildings-in-terms-of-building-height_tbl1_336543736
*/

export const building3DData = [
	{
		height: 0,
		color: '#ffffff'
	},
	{
		height: 9, // 1-3 floors, low-rise buildings
		color: '#853647'
	},
	{
		height: 21, // 4-6 floors, multi-story buildings
		color: '#7c3280'
	},
	{
		height: 30, // 7-9 floors, multi-story buildings
		color: '#513681'
	},
	{
		height: 50, // 10-16 floors, small high-rise buildings
		color: '#353d80'
	},
	{
		height: 100, // 17-40 floors, high-rise buildings
		color: '#32627d'
	},
	{
		height: 1000, // >40 floors, ultra-high-rise buildings
		color: '#328079'
	}
];

export const painted3DBuildings = {
	id: 'painted-3d-buildings',
	source: 'composite',
	'source-layer': 'building',
	filter: ['==', 'extrude', 'true'],
	type: 'fill-extrusion',
	minzoom: 15,
	paint: {
		'fill-extrusion-color': [
			'interpolate',
			['linear'],
			['get', 'height'],
			building3DData[0].height,
			building3DData[0].color,
			building3DData[1].height,
			building3DData[1].color,
			building3DData[2].height,
			building3DData[2].color,
			building3DData[3].height,
			building3DData[3].color,
			building3DData[4].height,
			building3DData[4].color,
			building3DData[5].height,
			building3DData[5].color,
			building3DData[6].height,
			building3DData[6].color
		],
		// Use an 'interpolate' expression to
		// add a smooth transition effect to
		// the buildings as the user zooms in.
		'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
		'fill-extrusion-base': [
			'interpolate',
			['linear'],
			['zoom'],
			15,
			0,
			15.05,
			['get', 'min_height']
		],
		'fill-extrusion-opacity': 0.7
	}
};

export const grayScale3DBuildings = {
	id: 'grayscale-3d-buildings',
	source: 'composite',
	'source-layer': 'building',
	filter: ['==', 'extrude', 'true'],
	type: 'fill-extrusion',
	minzoom: 15,
	paint: {
		'fill-extrusion-color': '#aaa',
		// Use an 'interpolate' expression to
		// add a smooth transition effect to
		// the buildings as the user zooms in.
		'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
		'fill-extrusion-base': [
			'interpolate',
			['linear'],
			['zoom'],
			15,
			0,
			15.05,
			['get', 'min_height']
		],
		'fill-extrusion-opacity': 0.65
	}
};

export const rectangleDrawMode = 'drag_rectangle';
export const circleDrawMode = 'drag_circle';
export const polygonDrawMode = 'draw_polygon';
export const simpleSelectMode = 'simple_select';
export const directSelectMode = 'direct_select';
export const staticMode = 'static';

// export const defaultMapStyle = 'kittyhawkio/ckrzr4dac081917mwbg7up7sf';
export const defaultMapStyle = 'ac-default';

export const whiteListedAloftSources = ['kh-customairspace', 'hillshade'];
export const customAirspaceLineLayerId = 'kh-customairspace-line';

export const mapLabelLayersToTranslate = [
	'contour-label',
	'building-number-label',
	'block-number-label',
	// 'road-label-outdoors',
	'golf-hole-label',
	'ferry-aerialway-label',
	// 'waterway-label',
	'natural-line-label',
	'natural-point-label',
	'water-line-label',
	'water-point-label',
	// 'poi-label',
	'transit-label',
	'airport-label',
	// 'settlement-subdivision-label',
	// 'settlement-minor-label',
	// 'settlement-major-label',
	'state-label',
	'country-label',
	'kh-laanc-label',
	'kh-uasfm-airspace-ceiling-labels'
];

export const defaultMapboxStyleValue = 'mapbox://styles/mapbox/streets-v11';

export const mapboxMapStyles = [
	{
		value: 'mapbox://styles/mapbox/streets-v11',
		name: 'Streets'
	},
	{
		value: 'mapbox://styles/mapbox/outdoors-v11',
		name: 'Outdoors'
	},
	{
		value: 'mapbox://styles/mapbox/light-v10',
		name: 'Light'
	},
	{
		value: 'mapbox://styles/mapbox/dark-v10',
		name: 'Dark'
	},
	{
		value: 'mapbox://styles/mapbox/satellite-v9',
		name: 'Satellite'
	},
	{
		value: 'mapbox://styles/mapbox/satellite-streets-v11',
		name: 'Satellite Streets'
	},
	{
		value: 'mapbox://styles/mapbox/navigation-day-v1',
		name: 'Navigation Day'
	},
	{
		value: 'mapbox://styles/mapbox/navigation-night-v1',
		name: 'Navigation Night'
	}
];

export const liveTrafficLayerId = 'live_traffic_layer';
export const liveTrafficTrailsLayerId = 'live_traffic_trails_layer';
export const liveTrafficSourceId = 'live_traffic_source';
export const selectedFeatureSourceId = 'selected_feature_source';
export const selectedFeatureLayerId = 'selected_feature_layer';
export const liveTrafficTrailsSourceId = 'live_traffic_trails_source';
export const laancAuthForFacilitySourceId = 'laanc_auth_for_facility_source';
export const laancAuthForFacilityLineLayerId = 'laanc_auth_for_facility_line_layer';
export const laancAuthForFacilityFillLayerId = 'laanc_auth_for_facility_fill_layer';

export const alwaysClickableLayers = [liveTrafficLayerId, laancAuthForFacilityFillLayerId];
export const noTranslateLayers = ['road-number-shield'];

export const greenLinePaintStyle = {
	'line-opacity': 1,
	'line-width': 6,
	'line-color': $success
};

export const greenFillPaintStyle = {
	'fill-opacity': 0.5,
	'fill-color': $success
};

export const defaultDrawingFillColor = 'rgba(54, 209, 138, .8)';

export { $white as defaultDrawingLineColor } from 'constants/styles';
