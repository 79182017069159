export const findUrlInStringAndReturnATagTemplate = string_ => {
	var urlRegex = /(https?:\/\/\S+)/g;
	return string_.replaceAll(urlRegex, function (url) {
		return `<a target='_blank' style="word-break: break-word" href="${url}">${url}</a>`;
	});
};

export const formatPhoneNumber = value => {
	var cleaned = ('' + value).replaceAll(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';

		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
};

export const validateDatePattern = date => {
	var date_regex = /^(?:\d{2}\/){2}\d{2}$/;
	return date_regex.test(date);
};

// This expression matches email addresses, and checks that they are of the proper form. Taken from regexr.com/2rhq7
export const emailAddressRegex =
	/[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*@(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?/;

// This expression matches a hyphen separated US phone number, of the form ANN-NNN-NNNN, where A is between 2 and 9 and N is between 0 and 9.

export const coordinatesRegex = /^(-?\d+(\.\d*)?)\s*,\s*(-?\d+(\.\d*)?)$/gim;

export const atLeast1Uppercase = /[A-Z]/;
export const atLeast1Lowercase = /[a-z]/;
export const atLeast1Number = /\d/;
export const atLeast1SpecialCharacter = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/; //Characters allowed by cognito: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
