import { createSlice } from '@reduxjs/toolkit';
import { browserTimeZone } from 'utils/timeHelpers';

const initialState = {
	id: null,
	title: '',
	status: '',
	starts_at: null,
	notes: '',
	mission_participants: [],
	timezone: browserTimeZone,
	days: 0,
	hours: 0,
	minutes: 30,
	pre_assigned_aircraft: [],
	pre_assigned_batteries: [],
	tags: [],
	workflow: null,
	sgiWaiverLoadingCount: 0
};

const missionSlice = createSlice({
	name: 'mission',
	initialState,
	reducers: {
		resetMissionSlice() {
			return initialState;
		},
		setMissionData(state, action) {
			return { ...state, ...action.payload };
		},
		setSGIWaiverLoadingCount(state, action) {
			state.sgiWaiverLoadingCount = state.sgiWaiverLoadingCount + action.payload;
		}
	}
});

export const { resetMissionSlice, setMissionData, setSGIWaiverLoadingCount } = missionSlice.actions;

export default missionSlice.reducer;

export const sgiWaiverLoadingCountSelector = state => state.mission.sgiWaiverLoadingCount;
