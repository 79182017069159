import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	dataLoading: false
};

const flightPathViewer = createSlice({
	name: 'flightPathViewer',
	initialState,
	reducers: {
		setFlightPathData(state, action) {
			state.data = action.payload;
		},
		setFlightPathDataLoading(state, action) {
			state.dataLoading = action.payload;
		}
	}
});

export const { setFlightPathData, setFlightPathDataLoading } = flightPathViewer.actions;

export default flightPathViewer.reducer;

export const flightPathDataSelector = state => state.flightPathViewer.data;
export const flightPathDataLoadingSelector = state => state.flightPathViewer.dataLoading;
