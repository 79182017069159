import { jwtDecode } from 'jwt-decode';
import { convertUnixToMomentObject } from 'utils/timeHelpers';
import dayjs from 'utils/customDayJS';

export const checkTokenExpired = token => {
	const decodedToken = jwtDecode(token);
	const tokenExpiration = decodedToken.exp;
	const tokenExpirationBuffer = convertUnixToMomentObject(tokenExpiration)
		.subtract(5, 'minutes')
		.unix();
	const rightNow = dayjs().unix();
	// console.log(
	// 	'NOW',
	// 	dayjs.unix(rightNow).format(timeFormat10),
	// 	'BUFFER',
	// 	dayjs.unix(tokenExpirationBuffer).format(timeFormat10),
	// 	'>>>>>>> EXP',
	// 	dayjs.unix(tokenExpiration).format(timeFormat10)
	// );
	return rightNow >= tokenExpirationBuffer ? true : false;
};
