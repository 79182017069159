import { SENTRY_DSN, REACT_APP_ENV, REACT_APP_VERSION } from 'constants/environmentVariables';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
	Sentry.init({
		dsn: SENTRY_DSN,
		environment: REACT_APP_ENV,
		integrations: [
			new Sentry.BrowserTracing(),
			new Sentry.Replay({
				maskAllText: true,
				blockAllMedia: true
			})
		],
		maxBreadcrumbs: 50,
		tracesSampleRate: 1,
		release: REACT_APP_VERSION,
		normalizeDepth: 10,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0.05
	});
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
