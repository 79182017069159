import 'utils/polyfills';
import 'utils/detectInternetExplorer';
import { createRoot } from 'react-dom/client';
import Root from 'Root';
import AppRoutes from 'AppRoutes';
import * as serviceWorker from './serviceWorker';
import { initSentry } from 'services/sentry';
import 'services/configureTranslations';

initSentry();

const container = document.querySelector('#root');
const root = createRoot(container);
root.render(
	<Root>
		<AppRoutes />
	</Root>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
