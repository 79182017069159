import { point, polygon, lineString, featureCollection } from '@turf/helpers';

export const createPointFeature = ({ coordinates, id, properties, lat, long, ...rest }) => {
	if (coordinates && (lat || long)) {
		throw new Error(
			'Invalid parameters - Provide either a coordinates parameter OR lat and long parameters.'
		);
	}

	if (!coordinates && (lat === undefined || long === undefined)) {
		throw new Error('The coordinates parameter OR lat and long parameters are required.');
	}
	const coords = coordinates ? coordinates : [long, lat];
	const geoJSONPoint = point(coords, properties, { id });
	return {
		...geoJSONPoint,
		...rest
	};
};

export const createPolygonFeature = ({ coordinates, id, properties, ...rest }) => {
	const geoJSONPolygon = polygon(coordinates, properties, { id });
	return {
		...geoJSONPolygon,
		...rest
	};
};

export const createLineStringFeature = ({ coordinates, id, properties, ...rest }) => {
	const geoJSONLineString = lineString(coordinates, properties, { id });
	return {
		...geoJSONLineString,
		...rest
	};
};

export const createFeatureCollection = ({ features, id, ...rest }) => {
	const geoJSONFeatureCollection = featureCollection(features, { id });
	return {
		...geoJSONFeatureCollection,
		...rest
	};
};

export const createGeoJSONFeatureLayer = ({ type, id, feature, paint, layout }) => {
	return {
		type,
		id,
		source: {
			type: 'geojson',
			data: feature
		},
		paint: {
			...paint
		},
		layout: {
			...layout
		}
	};
};
