import { combineReducers } from 'redux';
import fileUploadSlice from 'reducers/fileUploadSlice';
import globalSlice from 'reducers/globalSlice';
import laancSlice from 'reducers/laancSlice';
import manageTablesSlice from 'reducers/manageTablesSlice';
import mapSlice from 'reducers/mapSlice';
import missionSlice from 'reducers/missionSlice';
import reportsSlice from 'reducers/reportsSlice';
import rolesSlice from 'reducers/rolesSlice';
import userAuthSlice from 'reducers/userAuthSlice';
import streamingSlice from 'reducers/streamingSlice';
import liveTrafficSlice from 'reducers/liveTrafficSlice';
import flightPathViewerSlice from 'reducers/flightPathViewerSlice';
import activitiesSlice from 'reducers/activitiesSlice';

export default combineReducers({
	fileUpload: fileUploadSlice,
	global: globalSlice,
	laanc: laancSlice,
	manageTables: manageTablesSlice,
	map: mapSlice,
	mission: missionSlice,
	reports: reportsSlice,
	roles: rolesSlice,
	streaming: streamingSlice,
	userAuth: userAuthSlice,
	liveTraffic: liveTrafficSlice,
	flightPathViewer: flightPathViewerSlice,
	activities: activitiesSlice
});
