import { addSnackbarAlerts, setGlobalIsLoading } from 'reducers/globalSlice';
import { t } from 'i18next';

export const handleErrors = ({ dispatch, errors }) => {
	if (!errors.response) {
		return errors;
	}
	dispatch(setGlobalIsLoading(false));

	if (errors.response.status === 403 || errors.response.status === 401) {
		//403 and 401 errors have a different structure. Overwrite the message with the generic "Invalid Authentication" message below.
		const alerts = [
			{
				type: 'error',
				message: t('Invalid Authentication'),
				status: errors.response.status
			}
		];
		dispatch(addSnackbarAlerts(alerts));
	} else {
		if (errors.response.status === 0) {
			return dispatch(
				addSnackbarAlerts([
					{
						type: 'error',
						message: `${t('An error occurred and has been reported to our team')}.`
					}
				])
			);
		}
		if (errors.response.status === 429) {
			return dispatch(
				addSnackbarAlerts([
					{
						type: 'error',
						message: `${t('Too many requests')}. ${t('Please wait a few minutes and try again')}.`
					}
				])
			);
		}
		const errorList = errors?.response?.data?.errors;
		const isV1ErrorStructure = !Array.isArray(errorList); //V1 errorsList is an object while the old response was an array
		const alerts = [];
		if (!isV1ErrorStructure && errorList) {
			const errorMessage = errorList[0]?.detail
				? errorList[0].detail
				: `${t('An error occurred and has been reported to our team')}.`;
			alerts.push({
				type: 'error',
				message: errorMessage
			});
		}

		if (isV1ErrorStructure && errorList) {
			for (const error of Object.entries(errorList)) {
				const message = error[1].join(', '); //error[1] is an array of text describing the error. Using .join here ensures that all messages will be displayed if there is more than one.
				alerts.push({ type: 'error', isOpen: true, message, status: errors.response.status });
			}
		}

		dispatch(addSnackbarAlerts(alerts));
	}
};
