import { t } from 'i18next';
import { handleLaancState } from 'components/manage/ManageLAANC/laancUtils';
import { browserTimeZone, unixToStringWithTimeZone } from 'utils/timeHelpers';

export const getLocationSearchOption = () => ({
	type: 'location',
	label: t('Location'),
	inputs: {}
});

export const lowAltitudeFeet = 0;

export const midAltitudeFeet = 1000;

export const highAltitudeFeet = 5000;

export const airMeshProviderName = 'airmesh_sdk';

export const getLaancAuthFacilityColumns = () => [
	{
		field: 'facility',
		headerName: t('Facility'),
		width: 100
	},
	{
		field: 'state',
		headerName: t('Status'),
		width: 300,
		renderCell: params => {
			return handleLaancState(params.row.state);
		}
	},
	{
		width: 225,
		headerName: t('Start'),
		field: 'starts_at',
		type: 'dateTimeWithZoneFromUnix',
		renderCell: params => {
			return unixToStringWithTimeZone(params.row.starts_at, browserTimeZone);
		}
	},
	{
		width: 225,
		headerName: t('End'),
		field: 'stops_at',
		type: 'dateTimeWithZoneFromUnix',
		renderCell: params => {
			return unixToStringWithTimeZone(params.row.stops_at, browserTimeZone);
		}
	}
];
