//Source: https://www.taniarascia.com/websockets-in-redux/

import {
	airMeshPusherInstanceSelector,
	resetAMState,
	setAMConnectionError,
	setAMConnectionLoading,
	setAMPusherInstance,
	setAMTrafficFeatures,
	setAMWebSocketConnected,
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	airMeshProviderTypesSelector,
	pauseTrafficUpdatesSelector,
	airMeshAircraftTypesSelector
} from 'reducers/liveTrafficSlice';
import dayjs from 'utils/customDayJS';
import { normalizeAMData } from 'components/airSpace/airspaceUtils';
import Pusher from 'pusher-js';
import {
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY,
	BASE_JWT_URL
} from 'constants/environmentVariables';
import { TOKEN } from 'constants/localStorageConstants';
import { currentAccountIdSelector } from 'reducers/userAuthSlice';

export const airMeshMiddleware = () => parameters => next => action => {
	const { type } = action;
	const { dispatch, getState } = parameters;
	const currentAccountId = currentAccountIdSelector(getState());
	const airMeshPusherInstance = airMeshPusherInstanceSelector(getState());

	const token = localStorage.getItem(TOKEN);

	const channelId = `private-account-${currentAccountId}-telemetry`;

	const handleWebsocketError = () => {
		dispatch(setAMConnectionLoading(false));
		dispatch(setAMWebSocketConnected(false));
		dispatch(setAMConnectionError(true));
		console.error('Failed to connect to Air Mesh');
	};

	const handleWebsocketClosed = () => {
		dispatch(resetAMState());
	};

	const setAircraftTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.aircraft_type);
		const existingTypes = airMeshAircraftTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshAircraftTypes(uniqueTypes));
	};

	const setProviderTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.provider);
		const existingTypes = airMeshProviderTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshProviderTypes(uniqueTypes));
	};

	const handleApplyUpdates = event => {
		const pauseTrafficUpdates = pauseTrafficUpdatesSelector(getState());
		if (!pauseTrafficUpdates) {
			const updatedFeatures = updateFeatures(event.data, getState().liveTraffic.airMeshFeatures);
			dispatch(setAMTrafficFeatures(updatedFeatures));
			setAircraftTypes(updatedFeatures);
			setProviderTypes(updatedFeatures);
		}
	};

	switch (type) {
		case 'airMesh/connect': {
			const newPusherInstance = new Pusher(AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY, {
				cluster: AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
				channelAuthorization: {
					endpoint: `${BASE_JWT_URL}/api/pusher-auth`,
					headers: {
						Authorization: 'Bearer ' + token
					}
				}
			});

			dispatch(setAMPusherInstance(newPusherInstance));

			const channel = newPusherInstance.subscribe(channelId);

			//Listen for track-event data
			channel.bind('track-event', event => {
				handleApplyUpdates(event);
			});

			//Listen for events to handle errors
			channel.bind('pusher:subscription_error', e => {
				handleWebsocketError(e.error);
			});

			newPusherInstance.connection.bind('connected', () => {
				dispatch(setAMWebSocketConnected(true));
				dispatch(setAMConnectionError(false));
				dispatch(setAMConnectionLoading(false));
			});

			newPusherInstance.connection.bind('failed', () => {
				handleWebsocketError();
			});

			newPusherInstance.connection.bind('unavailable', () => {
				handleWebsocketError();
			});

			newPusherInstance.connection.bind('disconnected', () => {
				handleWebsocketClosed();
			});
			break;
		}
		case 'airMesh/disconnect': {
			if (airMeshPusherInstance) {
				airMeshPusherInstance.disconnect();
			}
			break;
		}
		default: {
			break;
		}
	}

	return next(action);
};

export const airMeshConnect = payload => ({ type: 'airMesh/connect', payload });
export const airMeshDisconnect = () => ({ type: 'airMesh/disconnect' });

export const updateFeatures = (incomingFeatures, currentFeatures) => {
	const normalizedIncomingFeatures = normalizeAMData(incomingFeatures);
	const updatedFeatures = currentFeatures.map(currentFeature => {
		//check if the same feature is in the incoming features, but with new information
		const featureWithNewInformation = normalizedIncomingFeatures.find(
			featureWithNewInformation => featureWithNewInformation.id === currentFeature.id
		);

		const altitudeChange = featureWithNewInformation?.properties?.altitude
			? featureWithNewInformation.properties.altitude - currentFeature.properties.altitude
			: 0;

		if (featureWithNewInformation) {
			const isADSB = featureWithNewInformation.properties.aircraft_type === 'adsb';
			let previousPositions = currentFeature.properties?.previous_positions
				? [...currentFeature.properties.previous_positions]
				: [];
			if (isADSB) {
				//Add an array of features to the current feature, which keeps track of the aircraft's previous positions.
				//Only add to the previous positions array if the incoming feature's position is different that the last previous position

				const lastPreviousPosition = previousPositions[previousPositions.length - 1];

				const previousLong = lastPreviousPosition
					? lastPreviousPosition.geometry.coordinates[0]
					: null;
				const previousLat = lastPreviousPosition
					? lastPreviousPosition.geometry.coordinates[1]
					: null;
				const currentLong = featureWithNewInformation.geometry.coordinates[0];
				const currentLat = featureWithNewInformation.geometry.coordinates[1];
				const isDifferentPosition = previousLong !== currentLong || previousLat !== currentLat;

				if (isDifferentPosition || previousPositions.length === 0) {
					let simplifiedFeatureForTrail = {
						...featureWithNewInformation,
						properties: {
							altitude: featureWithNewInformation.properties.altitude
						}
					};
					previousPositions.push(simplifiedFeatureForTrail);
				}

				//Remove any previous positions that are more than 5 minutes old
				const now = dayjs();
				const timeFrame = now.subtract(20, 'second');
				previousPositions = previousPositions.filter(previousPosition => {
					const previousPositionDate = dayjs(previousPosition.timestamp);
					return previousPositionDate > timeFrame;
				});
			}

			return {
				...featureWithNewInformation,
				altitude_change: altitudeChange,
				properties: {
					...featureWithNewInformation.properties,
					altitude_change: altitudeChange,
					previous_positions: previousPositions
				}
			};
		}
		return currentFeature;
	});

	const now = dayjs();
	const timeFrame = now.subtract(30, 'second');

	const updatedFeaturesOldFeaturesRemoved = updatedFeatures.filter(feature => {
		const featureDate = dayjs(feature.properties.time_of_applicability);
		return featureDate > timeFrame;
	});

	const newFeatures = normalizedIncomingFeatures.filter(
		incomingFeature =>
			!updatedFeaturesOldFeaturesRemoved.find(
				currentFeature => currentFeature.id === incomingFeature.id
			)
	);

	return [...updatedFeaturesOldFeaturesRemoved, ...newFeatures];
};
