import axios from 'axios';
import { SELECTED_LANGUAGE, TOKEN } from 'constants/localStorageConstants';
import { refreshCognito } from 'services/aws-cognito';
import { checkTokenExpired } from 'utils/tokenUtils';

//This axios instance is used to pass authorization headers to all aircontrol endpoints
//and to manage the token and refresh token flow via interceptors
//External api calls should not use axiosInstance. They should use the plain axios package.
const axiosInstance = axios.create();

let tokenIsRefreshing = false;
let requestQueue = [];

const processQueue = (error, token = null) => {
	for (const prom of requestQueue) {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	}
	requestQueue = [];
};

axiosInstance.interceptors.request.use(
	async originalRequest => {
		let token = localStorage.getItem(TOKEN);
		if (token) {
			const tokenExpired = checkTokenExpired(token);

			if (tokenExpired) {
				//Token will expire in 5 min or less - initiate refresh flow
				if (tokenIsRefreshing) {
					// Refresh token is already being fetched
					return new Promise((resolve, reject) => {
						// Add request to queue as a new promise
						requestQueue.push({ resolve, reject });
					})
						.then(token => {
							//This promise is resolved after the new token is received. The new token is passed in.
							originalRequest.headers.Authorization = `Bearer ${token}`;
							return originalRequest;
						})
						.catch(error => {
							throw error;
						});
				} else {
					//Return a new promise
					return new Promise((resolve, reject) => {
						// Use refresh token to get new token
						tokenIsRefreshing = true;

						refreshCognito()
							.then(newTokens => {
								token = newTokens?.id_token;
								originalRequest.headers.Authorization = `Bearer ${token}`;
								tokenIsRefreshing = false;
								//Resolve the requests in the queue and pass new token
								processQueue(null, token);
								//Resolve the current request
								resolve(originalRequest);
							})
							.catch(error => {
								processQueue(error, null);
								reject(error);
							});
					});
				}
			}
			// Set authorization header if no refresh flow needed
			originalRequest.headers.Authorization = `Bearer ${token}`;
			const language = localStorage.getItem(SELECTED_LANGUAGE);
			originalRequest.headers['Accept-Language'] = language;
		}
		return originalRequest;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default axiosInstance;
