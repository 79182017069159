import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	showReloadMsg: { isOpen: false, versionUpdate: null },
	snackbarAlerts: [
		//see structure in AlertMessage.js propTypes
	],
	aircraftMakes: [],
	aircraftModels: [],
	batteryMakes: [],
	batteryModels: [],
	components: [],
	trafficPanelOpen: false,
	accountTagGroups: [],
	accountRoles: []
};

const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setGlobalIsLoading(state, action) {
			state.isLoading = action.payload;
		},
		setShowReloadMessage(state, action) {
			state.showReloadMsg = action.payload;
		},
		resetSnackbarAlerts(state, action) {
			state.snackbarAlerts = action.payload;
		},
		addSnackbarAlerts(state, action) {
			state.snackbarAlerts = [...state.snackbarAlerts, ...action.payload];
		},
		setAircraftMakes(state, action) {
			state.aircraftMakes = action.payload;
		},
		setAircraftModels(state, action) {
			state.aircraftModels = action.payload;
		},
		setBatteryMakes(state, action) {
			state.batteryMakes = action.payload;
		},
		setBatteryModels(state, action) {
			state.batteryModels = action.payload;
		},
		setComponents(state, action) {
			state.components = action.payload;
		},
		setTrafficPanelOpen(state, action) {
			state.trafficPanelOpen = action.payload;
		},
		setAccountTagGroups(state, action) {
			state.accountTagGroups = action.payload;
		},
		setAccountRoles(state, action) {
			state.accountRoles = action.payload;
		},
		resetGlobalSlice() {
			return initialState;
		}
	}
});

export const {
	setGlobalIsLoading,
	setShowReloadMessage,
	resetSnackbarAlerts,
	addSnackbarAlerts,
	resetGlobalSlice,
	setAircraftMakes,
	setAircraftModels,
	setBatteryMakes,
	setBatteryModels,
	setComponents,
	setTrafficPanelOpen,
	setAccountTagGroups,
	setAccountRoles
} = globalSlice.actions;

export default globalSlice.reducer;

export const globalIsLoadingSelector = state => state.global.isLoading;
export const showReloadMsgSelector = state => state.global.showReloadMsg;
export const snackbarAlertsSelector = state => state.global.snackbarAlerts;
export const aircraftMakesSelector = state => state.global.aircraftMakes;
export const aircraftModelsSelector = state => state.global.aircraftModels;
export const batteryMakesSelector = state => state.global.batteryMakes;
export const batteryModelsSelector = state => state.global.batteryModels;
export const componentsSelector = state => state.global.components;
export const trafficPanelOpenSelector = state => state.global.trafficPanelOpen;
export const accountTagGroupsSelector = state => state.global.accountTagGroups;
export const accountRolesSelector = state => state.global.accountRoles;
