import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { $black, $elevated } from 'constants/styles';

function RectangleChip({ label, color }) {
	const styles = {
		statusLabel: {
			color: color === $elevated ? $black : '#fff',
			padding: '5px 10px',
			fontSize: '13px',
			lineHeight: '18px',
			borderRadius: '2px',
			fontWeight: 'bold',
			textAlign: 'center',
			width: 'fit-content',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			height: 'fit-content',
			backgroundColor: color
		}
	};

	if (label === undefined || label === null) {
		return null;
	}

	return <Box sx={styles.statusLabel}>{label}</Box>;
}

RectangleChip.propTypes = {
	label: PropTypes.string,
	color: PropTypes.string
};

export default RectangleChip;
