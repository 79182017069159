import {
	SELECTED_MAP_LAYERS,
	SELECTED_MAP_STYLE,
	SELECTED_MAP_SOURCES,
	SELECTED_MAPBOX_STYLE,
	SELECTED_SEARCH_TYPE,
	LIVE_TRAFFIC_COLUMNS,
	SGI_WAIVER_OPERATOR_ADDRESS
} from 'constants/localStorageConstants';
import { defaultMapStyle, defaultMapboxStyleValue } from 'constants/mapConstants';
import { getLocationSearchOption } from 'components/airSpace/airspaceConstants';
export const saveSelectedMapLayersToLS = layers => {
	localStorage.setItem(SELECTED_MAP_LAYERS, JSON.stringify(layers));
};

export const getSelectedMapLayersFromLS = () => {
	const layerSettings = localStorage.getItem(SELECTED_MAP_LAYERS);
	return layerSettings ? JSON.parse(layerSettings) : {};
};

export const saveSelectedMapSourcesToLS = layers => {
	localStorage.setItem(SELECTED_MAP_SOURCES, JSON.stringify(layers));
};

export const getSelectedMapSourcesFromLS = () => {
	const sourceSettings = localStorage.getItem(SELECTED_MAP_SOURCES);
	return sourceSettings ? JSON.parse(sourceSettings) : {};
};

export const saveSelectedMapStyleToLS = styleId => {
	localStorage.setItem(SELECTED_MAP_STYLE, styleId);
};

export const getSelectedMapStyleFromLS = () => {
	const selectedMapStyle = localStorage.getItem(SELECTED_MAP_STYLE);
	return selectedMapStyle ? selectedMapStyle : defaultMapStyle;
};

export const saveSelectedMapboxStyleToLS = styleId => {
	localStorage.setItem(SELECTED_MAPBOX_STYLE, styleId);
};

export const getSelectedMapBoxStyleFromLS = () => {
	const selectedMapStyle = localStorage.getItem(SELECTED_MAPBOX_STYLE);
	return selectedMapStyle ? selectedMapStyle : defaultMapboxStyleValue;
};

export const saveSelectedSearchTypeToLS = searchType => {
	localStorage.setItem(SELECTED_SEARCH_TYPE, JSON.stringify(searchType));
};

export const getSelectedSearchTypeFromLS = () => {
	const selectedSearchType = localStorage.getItem(SELECTED_SEARCH_TYPE);
	return selectedSearchType ? JSON.parse(selectedSearchType) : getLocationSearchOption();
};

export const getLiveTrafficColumnsFromLS = () => {
	const liveTrafficColumns = localStorage.getItem(LIVE_TRAFFIC_COLUMNS);
	return liveTrafficColumns ? JSON.parse(liveTrafficColumns) : [];
};

export const saveLiveTrafficColumnsToLS = columns => {
	localStorage.setItem(LIVE_TRAFFIC_COLUMNS, JSON.stringify(columns));
};

export const saveSGIWaiverOperatorAddressToLS = address => {
	localStorage.setItem(SGI_WAIVER_OPERATOR_ADDRESS, address);
};

export const getSGIWaiverOperatorAddressFromLS = () => {
	const savedAddress = localStorage.getItem(SGI_WAIVER_OPERATOR_ADDRESS);
	return savedAddress ? savedAddress : '';
};
