import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { reduxStore } from 'services/configureStore';
import AppThemeProvider from './AppThemeProvider';
import * as Sentry from '@sentry/react';
import { MUI_KEY, INTERCOM_ID } from 'constants/environmentVariables';
import { IntercomProvider } from 'react-use-intercom';
import { LocaleConnector } from 'LocaleConnector';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(MUI_KEY);

const path = window.location.pathname;
const isLoginPage = path === '/login';

const Root = ({ children }) => {
	return (
		<Provider store={reduxStore}>
			<LocaleConnector />
			{/*//shouldInitialize reads env var "TEST" to prevent Intercom from causing tests to time out*/}
			<IntercomProvider
				appId={INTERCOM_ID}
				autoBoot
				shouldInitialize={process.env.TEST !== 'true'}
				initializeDelay={isLoginPage ? 5000 : 0}
			>
				<AppThemeProvider>
					<BrowserRouter>{children}</BrowserRouter>
				</AppThemeProvider>
			</IntercomProvider>
		</Provider>
	);
};

export default Sentry.withProfiler(Root);
