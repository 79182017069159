import {
	laancLayerColors,
	laancStateOptions,
	laancUncloseableStates
} from 'components/manage/ManageLAANC/laancConstants';
import { featureCollection, point } from '@turf/helpers';
import distance from '@turf/distance';
import {
	getHoursFromSeconds,
	getMinsFromSeconds,
	convertUnixToMomentObject,
	unixRoundDownToNearestMinute
} from 'utils/timeHelpers';
import { createGeoJSONFeatureLayer, createPolygonFeature } from 'utils/geoJsonHelpers';
import { setQueryParamsFromObject } from 'utils/urlHelpers';
import { t } from 'i18next';
import dayjs from 'utils/customDayJS';
import center from '@turf/center';
import RectangleChip from 'components/elements/RectangleChip';

export const mapLaancToNewDuplicate = (laanc, pilot) => {
	return {
		...laanc,
		laancCreateType: laanc.type,
		laancAltitude: laanc.altitude,
		laancCreatePilot: pilot,
		id: 0,
		isDuplicateLaanc: true,
		laancCreatePreCheckValid: true
	};
};

export const convertUserToLAANCPilot = user => ({
	id: user.id,
	first_name: user.firstname,
	last_name: user.lastname,
	phone: user.phone_number,
	name: `${user.lastname}, ${user.firstname}`,
	faa_107_issued: user.faa_107_issued,
	faa_107_number: user.faa_107_number,
	verified_phone: user.verified_phone
});

export const checkFurtherCoordinationEnabled = features => {
	return features.some(feature => feature.properties.APT1_ENABLED?.includes('107-FC'));
};

export const checkAutoApprovalEnabled = features => {
	return features.some(feature => feature.properties.APT1_ENABLED?.includes('107-AA'));
};

const createAreaName = index => `laanc-area-${index}`;
const createOutlineName = index => `laanc-outline-${index}`;

export const addPolygonLayer = (type, coordinates, index, statusColor, map) => {
	let layerType = {
		area: {
			type: 'fill',
			id: createAreaName(index),
			paint: {
				'fill-color': laancLayerColors[statusColor].fill,
				'fill-opacity': 0.5
			},
			layout: {}
		},
		outline: {
			type: 'line',
			id: createOutlineName(index),
			paint: {
				'line-color': laancLayerColors[statusColor].line,
				'line-opacity': 1,
				'line-width': 6
			},
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			}
		}
	};

	const polygon = createPolygonFeature({
		coordinates
	});

	const featureLayer = createGeoJSONFeatureLayer({
		id: layerType[type].id,
		type: layerType[type].type,
		feature: polygon,
		paint: layerType[type].paint,
		layout: layerType[type].layout
	});

	map.addLayer(featureLayer);
};

export const addStaticPolygon = (list, draw, map) => {
	for (const [index, item] of list.entries()) {
		const colorStatus = item.state;
		const coord = item.operation.geometry.coordinates;
		draw.deleteAll();
		addPolygonLayer('area', coord, index, colorStatus, map);
		addPolygonLayer('outline', coord, index, colorStatus, map);
	}
};

/**
 * @param {Array}
 * @ex: [[-122.48841593538896,37.637899643327465],[-122.494735432945,37.6405604836551],[-122.49473543331719,37.643803262253115],[-122.49073622201307,37.64413100548811],[-122.48841593538896,37.637899643327465]]
 */
export const getPolygonCenter = points => {
	if (!Array.isArray(points)) {
		return null;
	}

	let featureArray = [];

	for (const item of points) {
		featureArray.push(point(item));
	}

	const features = featureCollection(featureArray);
	const c = center(features);
	return c.geometry.coordinates;
};

/**
 * measure the distance from the center to shape to each point.
 * distance should not exceed 1/2 of maxKilometer
 * @param {array} coordinatesList
 */
export const withInMaxDistance = coordinatesList => {
	const maxKilometer = 18.52; // equal to 10 nautical miles
	const centerPointOfShape = getPolygonCenter(coordinatesList);
	// measure each point with the center of the shape
	if (centerPointOfShape) {
		return coordinatesList.every(p => {
			const from = point(centerPointOfShape);
			const to = point(p);
			const options = { units: 'kilometers' };
			return distance(from, to, options) < maxKilometer / 2;
		});
	} else {
		return false;
	}
};

export const getLaancStateObjFromValue = value => {
	return laancStateOptions().find(o => o.value === value);
};

export const convertLaancRecordToReducerPropertiesForEdit = record => {
	const { stops_at, starts_at, pilot, altitude, timezone, id, type } = record;
	const diffInSeconds = stops_at - starts_at;
	const laancReducerStructure = {
		laancCreateType: type,
		laancCreateTimestamp: {
			date: convertUnixToMomentObject(starts_at),
			hours: Number.parseInt(getHoursFromSeconds(diffInSeconds)),
			mins: Number.parseInt(getMinsFromSeconds(diffInSeconds))
		},
		laancCreateStartTime: convertUnixToMomentObject(starts_at),
		laancAltitude: altitude,
		laancCreatePilot: {
			...convertUserToLAANCPilot(pilot)
		},
		laancCreatePreCheckValid: true,
		timezone,
		laancPayload: record,
		laancEditMode: {
			isEdit: true,
			selectedId: id
		}
	};

	return laancReducerStructure;
};

export const renderLaancEligibilityMessage = ({
	laancAirspaceStatus,
	laancAltitude,
	laancCeiling,
	styles,
	laancCreateType,
	featuresCollection
}) => {
	const { outsideAirspace, FC, AA } = laancAirspaceStatus;
	let message = <p style={styles.default} />;

	if (featuresCollection?.features?.length > 0) {
		//Only show message if a shape is drawn

		// polygon outside of controlled Airspace
		if (outsideAirspace && laancAltitude !== 0) {
			message = (
				<p style={styles?.red}>
					{`${t('LAANC is not available for operations outside of controlled airspace')}.`}
				</p>
			);
		}

		if (!(laancAltitude !== 0 && laancCeiling !== Number.POSITIVE_INFINITY)) {
			message = <p style={styles.default} />;
		}

		// polygon inside Controlled Airspace
		// AA = auto approval
		if (
			AA &&
			laancAltitude <= laancCeiling &&
			laancCeiling !== Number.POSITIVE_INFINITY &&
			laancCeiling !== 0
		) {
			message = (
				<p style={styles?.green}>
					{`${t('Eligible for auto-approval up to')} `}
					<b>{`${laancCeiling}ft.`}</b>
				</p>
			);
		}

		// FC = futher cooordination
		if (!FC && laancAltitude > laancCeiling && laancCeiling !== 0) {
			message = (
				<p style={styles?.orange}>
					{`${t('No further coordination available for the planned operation area')}.`}
				</p>
			);
		}

		// FC = futher cooordination for Commercial
		if (FC && laancCreateType === 'laanc-107' && laancAltitude > laancCeiling) {
			message = (
				<p style={styles?.orange}>
					{`${t('Eligible for further coordination up to')} `}
					<b>{`${t('400ft')}.`}</b>
				</p>
			);
		}

		// AA = Auto approval for Recreational
		if (AA && laancCreateType === 'laanc-44809' && laancAltitude > laancCeiling) {
			message = (
				<p style={styles?.orange}>
					{`${t('Not eligible for authorization over')} `}
					<b>{`${laancCeiling}${t('ft')}.`}</b>
				</p>
			);
		}

		// if (FC && laancAltitude > laancCeiling) {
		// 	message = (
		// 		<p style={styles?.orange}>{'Eligible for further coordination up to 400ft.'}</p>
		// 	);
		// }
	}

	return <>{message}</>;
};

export const determineAuthorizationCloseable = ({ starts_at, stops_at, state }) => {
	/* This is used to determine whether the action should be labeled Close or Cancel, depending on if an authorization is currently active or not.
	Round start and stop times down to nearest minute because the user will mostly likely measure time in minute-level precision
	and not down to the seconds. This ensures that the button display logic will appear correct to the user.*/
	const roundedStart = unixRoundDownToNearestMinute(starts_at);
	const roundedStop = unixRoundDownToNearestMinute(stops_at);
	const currentTime = dayjs().unix();
	const showCloseButton = roundedStart <= currentTime && currentTime < roundedStop; // Show close button during active authorization
	const uncloseableState = laancUncloseableStates.includes(state);
	const closeable = !uncloseableState && showCloseButton;
	return closeable;
};

export const convertType = type => {
	if (type === 'laanc-107') return t('Commercial (107)');
	if (type === 'laanc-44809') return t('Recreational (44809)');
};

export const removeMissionIdFromQueryParams = (currentParameters, navigate) => {
	let updatedParameters = {
		...currentParameters
	};
	delete updatedParameters.missionId;
	setQueryParamsFromObject(updatedParameters, navigate);
};

export const removePolygonFromQueryParams = (currentQueryParameters, navigate) => {
	let updatedParameters = {
		...currentQueryParameters
	};
	delete updatedParameters.polygon;
	setQueryParamsFromObject(updatedParameters, navigate);
};

export const handleLaancState = value => {
	const state = getLaancStateObjFromValue(value);
	return <RectangleChip color={state?.color} label={state?.label} />;
};
