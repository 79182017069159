import { useDispatch } from 'react-redux';
import { addSnackbarAlerts, setShowReloadMessage } from 'reducers/globalSlice';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import * as Sentry from '@sentry/react';

const CustomErrorBoundary = ({ children, fallback }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ErrorFallback = () => {
		if (fallback) {
			return fallback;
		} else {
			return (
				<Alert severity='error'>{`${t('An error occurred and has been reported to our team')}.`}</Alert>
			);
		}
	};

	const onError = error => {
		if (error.name === 'ChunkLoadError') {
			//This error is thrown when a new deploy causes the name of the js chunk to change
			//This action prompts user to refresh the page to get the needed chunk
			Sentry.captureException(error, { level: 'info', handled: true });
			dispatch(setShowReloadMessage({ isOpen: true, versionUpdate: 'patch' }));
		} else {
			Sentry.captureException(error);
			dispatch(
				addSnackbarAlerts([
					{
						type: 'error',
						message: `${t('An error occurred and has been reported to our team')}.`
					}
				])
			);
		}
	};

	return (
		<ErrorBoundary fallback={<ErrorFallback />} onError={onError}>
			{children}
		</ErrorBoundary>
	);
};

export default CustomErrorBoundary;
