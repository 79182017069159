import {
	$laancStatusAuthorized,
	$laancStatusCanceled,
	$laancStatusPending,
	$black
} from 'constants/styles';
import { t } from 'i18next';

export const heights = () => [
	{
		value: '0',
		label: t('0ft')
	},
	{
		value: '50',
		label: t('50ft')
	},
	{
		value: '100',
		label: t('100ft')
	},
	{
		value: '150',
		label: t('150ft')
	},
	{
		value: '200',
		label: t('200ft')
	},
	{
		value: '250',
		label: t('250ft')
	},
	{
		value: '300',
		label: t('300ft')
	},
	{
		value: '350',
		label: t('350ft')
	},
	{
		value: '400',
		label: t('400ft')
	}
];

export const types = () => [
	{
		value: 'laanc-44809',
		label: t('Recreational (44809)')
	},
	{
		value: 'laanc-107',
		label: t('Commercial (107)')
	}
];

export const laancLayerColors = {
	authorized: {
		fill: $laancStatusAuthorized,
		line: $laancStatusAuthorized,
		activeOutline: '#fff'
	},
	never_authorized: {
		fill: $laancStatusCanceled,
		line: $laancStatusCanceled,
		activeOutline: '#fff'
	},
	fc_pending: {
		fill: $laancStatusPending,
		line: $laancStatusPending,
		activeOutline: '#fff'
	}
};

export const laancConstants = () => [
	{
		width: 170,
		headerName: t('Reference #'),
		field: 'reference_number',
		sortBy: false,
		type: 'string'
	},
	{
		width: 200,
		headerName: t('Operator'),
		field: 'pilot',
		sortBy: false,
		type: 'userFullname',
		peopleCol: true
	},
	{
		width: 300,
		headerName: t('Status'),
		field: 'applied_state',
		sortBy: false,
		type: 'laancState'
	},
	{
		width: 200,
		headerName: t('Airport'),
		field: 'airspace_classes',
		sortBy: false,
		type: 'airport'
	},
	{
		width: 275,
		headerName: t('Start'),
		field: 'starts_at',
		sortBy: true,
		type: 'dateTimeWithZoneFromUnix'
	},
	{
		width: 275,
		headerName: t('End'),
		field: 'stops_at',
		sortBy: true,
		type: 'dateTimeWithZoneFromUnix'
	},
	{
		width: 170,
		headerName: t('Duration (minutes)'),
		field: 'id',
		sortBy: false,
		type: 'duration'
	},
	{
		width: 150,
		headerName: t('Height (ft)'),
		field: 'altitude',
		sortBy: false,
		type: 'altitude'
	},
	{
		width: 230,
		headerName: t('Type'),
		field: 'type',
		sortBy: false,
		type: 'type'
	}
];

export const laancMobileColumns = () => [
	{
		width: 150,
		headerName: t('Airport'),
		field: 'airspace_classes',
		sortBy: false,
		type: 'airport'
	},
	{
		width: 150,
		headerName: t('Operator'),
		field: 'pilot',
		sortBy: false,
		type: 'userFullname',
		peopleCol: true
	},
	{
		width: 250,
		headerName: t('Start'),
		field: 'starts_at',
		sortBy: true,
		type: 'dateTimeWithZoneFromUnix'
	},
	{
		width: 200,
		headerName: t('Status'),
		field: 'state',
		sortBy: false,
		type: 'laancState'
	},
	{
		width: 150,
		headerName: t('Duration (minutes)'),
		field: 'id',
		sortBy: false,
		type: 'duration'
	}
];

export const laancStateOptions = () => [
	{
		value: 'draft',
		label: t('Draft'),
		color: $black
	},
	{
		value: 'authorized',
		label: t('Authorized'),
		color: $laancStatusAuthorized
	},
	{
		value: 'never_authorized',
		label: t('Never Authorized'),
		color: $laancStatusCanceled
	},
	{
		value: 'rescinded_awaiting',
		label: t('Authorization Invalidated'),
		color: $laancStatusCanceled
	},
	{
		value: 'deauthorized',
		label: t('Deauthorized'),
		color: $laancStatusCanceled
	},
	{
		value: 'fc_pending',
		label: t('Further Coordination Pending'),
		color: $laancStatusPending
	},
	{
		value: 'authorized_complete',
		label: t('Authorized Complete'),
		color: $laancStatusAuthorized
	}
];

export const laancUneditableStates = [
	'never_authorized',
	'rescinded_awaiting',
	'deauthorized',
	'fc_pending',
	'authorized_complete'
];

export const laancUncancelableStates = ['never_authorized', 'deauthorized', 'authorized_complete'];

export const laancUncloseableStates = [
	'never_authorized',
	'rescinded_awaiting',
	'deauthorized',
	'fc_pending',
	'authorized_complete'
];

export const laancLinePaintStyle = {
	'line-opacity': 1,
	'line-width': 6,
	'line-color': [
		'match',
		['get', 'state'],
		'draft',
		$black,
		'authorized',
		$laancStatusAuthorized,
		'never_authorized',
		$laancStatusCanceled,
		'rescinded_awaiting',
		$laancStatusCanceled,
		'deauthorized',
		$laancStatusCanceled,
		'fc_pending',
		$laancStatusPending,
		'authorized_complete',
		$laancStatusAuthorized,
		/* other */ '#fff'
	]
};

export const laancFillPaintStyle = {
	'fill-opacity': 0.5,
	'fill-color': [
		'match',
		['get', 'state'],
		'draft',
		$black,
		'authorized',
		$laancStatusAuthorized,
		'never_authorized',
		$laancStatusCanceled,
		'rescinded_awaiting',
		$laancStatusCanceled,
		'deauthorized',
		$laancStatusCanceled,
		'fc_pending',
		$laancStatusPending,
		'authorized_complete',
		$laancStatusAuthorized,
		/* other */ '#fff'
	]
};
