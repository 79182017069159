import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { GOOGLE_TAG_ID } from 'constants/environmentVariables';

const GoogleAnalytics = ({ options }) => {
	const { pathname, search } = useLocation();
	const logPageChange = useCallback(
		(pathname, search = '') => {
			const path = pathname + search;
			let splittedPath = path.split('/');

			for (const [index, item] of splittedPath.entries()) {
				if (item !== '') {
					splittedPath[index] = isNaN(item) ? item : ':id';
				}
			}

			const page = splittedPath.join(' ').replaceAll(/\s/g, '/');
			const { location } = window;
			ReactGA.set({
				page,
				location: `${location.origin}${path}`,
				...options
			});
			ReactGA.pageview(page);
		},
		[options]
	);

	useEffect(() => {
		logPageChange(pathname, search);
	}, [pathname, search, logPageChange]);

	return null;
};

GoogleAnalytics.propTypes = {
	options: PropTypes.object
};

const RouteTracker = () => <GoogleAnalytics />;

const init = ({ options = {}, currentAccountId, token, userAccounts, userId }) => {
	const isLogin = token;

	const accounts = userAccounts ? userAccounts.map(item => item.id) : [];

	if (isLogin) {
		ReactGA.initialize(GOOGLE_TAG_ID, {
			gaOptions: {
				userId,
				accountId: currentAccountId,
				accountIds: accounts.toString()
			},
			...options
		});
	}

	return isLogin;
};

const googleAnalytics = {
	GoogleAnalytics,
	RouteTracker,
	init
};

export default googleAnalytics;
