import { createSlice } from '@reduxjs/toolkit';
import { browserTimeZone } from 'utils/timeHelpers';

const initialState = {
	laancCreateType: 'laanc-107',
	laancEditMode: { isEdit: false, selectedId: '' },
	laancCreateCurrentStep: 1,
	laancPayload: {},
	laancCheckResult: [],
	laancCreateTimestamp: { date: null, hours: 2, mins: 0 },
	laancCreateStartTime: null,
	laancCreatePreCheckValid: false,
	laancWithInDistance: true,
	laancCeiling: 0,
	laancAirspaceStatus: {
		outsideAirspace: null,
		FC: null, // futher cooordination
		AA: null // auto approval
	},
	laancAltitude: 0,
	laancCreatePilot: null,
	laancCreateSafetyJustification: '',
	laancShapeValid: true,
	timezone: browserTimeZone
};

const laancSlice = createSlice({
	name: 'laanc',
	initialState,
	reducers: {
		resetLaancSlice() {
			return initialState;
		},
		setLaancCreateType(state, action) {
			state.laancCreateType = action.payload;
		},
		setLaancCreateCurrentStep(state, action) {
			state.laancCreateCurrentStep = action.payload;
		},
		setLaancPayload(state, action) {
			state.laancPayload = action.payload;
		},
		setLaancCheckResult(state, action) {
			state.laancCheckResult = action.payload;
		},
		setLaancCreateTimestamp(state, action) {
			state.laancCreateTimestamp = action.payload;
		},
		setLaancCreateStartTime(state, action) {
			state.laancCreateStartTime = action.payload;
		},
		setLaancCreatePreCheckValid(state, action) {
			state.laancCreatePreCheckValid = action.payload;
		},
		setLaancWithInDistance(state, action) {
			state.laancWithInDistance = action.payload;
		},
		setLaancCeiling(state, action) {
			state.laancCeiling = action.payload;
		},
		setLaancAirspaceStatus(state, action) {
			state.laancAirspaceStatus = action.payload;
		},
		setLaancAltitude(state, action) {
			state.laancAltitude = action.payload;
		},
		setLaancCreatePilot(state, action) {
			state.laancCreatePilot = action.payload;
		},
		setLaancCreateSafetyJustification(state, action) {
			state.laancCreateSafetyJustification = action.payload;
		},
		setLaancShapeValid(state, action) {
			state.laancShapeValid = action.payload;
		},
		setLaancTimezone(state, action) {
			state.timezone = action.payload;
		},
		setDuplicateLaanc(state, action) {
			return {
				...state,
				...action.payload
			};
		},
		setLaancFromMission(state, action) {
			return {
				...state,
				...action.payload
			};
		},
		hydrateLaanc(state, action) {
			return {
				...state,
				...action.payload
			};
		},
		updateLaancPayloadOperation(state, action) {
			state.laancPayload = {
				...state.laancPayload,
				operation: action.payload
			};
		}
	}
});

export const {
	resetLaancSlice,
	setLaancCreateType,
	setLaancCreateCurrentStep,
	setLaancPayload,
	setLaancCheckResult,
	setLaancCreateTimestamp,
	setLaancCreateStartTime,
	setLaancCreatePreCheckValid,
	setLaancWithInDistance,
	setLaancCeiling,
	setLaancAirspaceStatus,
	setLaancAltitude,
	setLaancSubmitError,
	setLaancCreatePilot,
	setLaancCreateSafetyJustification,
	setLaancShapeValid,
	setLaancTimezone,
	setDuplicateLaanc,
	setLaancFromMission,
	hydrateLaanc,
	updateLaancPayloadOperation
} = laancSlice.actions;

export default laancSlice.reducer;

export const laancSelector = state => state.laanc;
