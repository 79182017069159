import { $danger } from 'constants/styles';
import { t } from 'i18next';

export const uasfmAirpsaceLayerName = 'uasfm-airspace';

export const iOSAppLink = 'https://apps.apple.com/us/app/id1472965046';
export const AndroidAppLink =
	'https://www.dropbox.com/sh/vzow19g22dq9js2/AABgWj-y_9oGF9qCoh8qZOXda?dl=0';

export const emailDomain = 'aloft.ai';

export const linkBaseUrl = `https://www.${emailDomain}`;

export const hoursOptions12 = () => [
	{ label: t('0h'), value: '0' },
	{ label: t('1h'), value: '1' },
	{ label: t('2h'), value: '2' },
	{ label: t('3h'), value: '3' },
	{ label: t('4h'), value: '4' },
	{ label: t('5h'), value: '5' },
	{ label: t('6h'), value: '6' },
	{ label: t('7h'), value: '7' },
	{ label: t('8h'), value: '8' },
	{ label: t('9h'), value: '9' },
	{ label: t('10h'), value: '10' },
	{ label: t('11h'), value: '11' },
	{ label: t('12h'), value: '12' }
];

export const hoursOptions23 = () => [
	...hoursOptions12(),
	{ label: t('13h'), value: '13' },
	{ label: t('14h'), value: '14' },
	{ label: t('15h'), value: '15' },
	{ label: t('16h'), value: '16' },
	{ label: t('17h'), value: '17' },
	{ label: t('18h'), value: '18' },
	{ label: t('19h'), value: '19' },
	{ label: t('20h'), value: '20' },
	{ label: t('21h'), value: '21' },
	{ label: t('22h'), value: '22' },
	{ label: t('23h'), value: '23' }
];

export const minutesOptionsQuarterHour = () => [
	{ label: t('0m'), value: '0' },
	{ label: t('15m'), value: '15' },
	{ label: t('30m'), value: '30' },
	{ label: t('45m'), value: '45' }
];

export const create365Days = () => {
	let days = [];
	for (let index = 0; index <= 365; index++) {
		days.push({ label: `${index}${t('d')}`, value: index.toString() });
	}
	return days;
};

export const create59Minutes = () => {
	let minutes = [];
	for (let index = 0; index <= 59; index++) {
		minutes.push({ label: `${index}${t('m')}`, value: index.toString() });
	}
	return minutes;
};

export const create59Seconds = () => {
	let seconds = [];
	for (let index = 0; index <= 59; index++) {
		seconds.push({ label: `${index}${t('s')}`, value: index.toString() });
	}
	return seconds;
};

export const acceptedImageTypes = {
	'image/jpeg': ['.jpg', '.jpeg'],
	'image/jpg': ['.jpg', '.jpeg'],
	'image/png': ['.png'],
	'image/gif': ['.gif'],
	'image/heic': ['.heic'],
	'video/mp4': ['.mp4'],
	'video/quicktime': ['.mov'],
	'video/x-msvideo': ['.avi'],
	'application/pdf': ['.pdf']
};

export const textFileTypes = {
	'text/plain': ['.txt']
};

export const acceptedDocumentTypes = {
	'application/pdf': ['.pdf'],
	'application/csv': ['.csv'],
	'application/json': ['.json'],
	'application/vnd.google-earth.kml+xml': ['.kml'],
	'application/shp': ['.shp'],
	'application/geojson': ['.geojson'],
	'application/doc': ['.doc'],
	'application/docx': ['.docx'],
	'application/msword': ['.doc'],
	'application/xls': ['.xls'],
	'application/xlsx': ['.xlsx'],
	'application/ppt': ['.ppt'],
	'application/pptx': ['.pptx'],
	'application/kmz': ['.kmz'],
	'application/vnd.ms-powerpoint': ['.ppt'],
	'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.pptx'],
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'text/csv': ['.csv'],
	'text/plain': ['.txt']
};
export const retiredOption = () => ({ value: true, label: t('Retired'), color: $danger });

export const TwentyMBFileSize = 20_000_000;

export const unauthenticatedRoutes = ['/post-login-flow', '/logout', '/unauthorized'];

export const appDefaultLanguage = 'en';

export const languageOptions = [
	{ value: appDefaultLanguage, label: 'English' },
	{ value: 'it', label: 'Italiano' }
];
