import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
	fileLoading: false,
	preUploadFilesList: [],
	filesList: []
};

const fileUploadSlice = createSlice({
	name: 'fileUpload',
	initialState,
	reducers: {
		resetFileUploadSlice() {
			return initialState;
		},
		setFileLoading(state, action) {
			state.fileLoading = action.payload;
		},
		setPreUploadFilesList(state, action) {
			state.preUploadFilesList = action.payload;
		},
		setFilesList(state, action) {
			state.filesList = [...state.filesList, ...action.payload];
		}
	}
});

export const { resetFileUploadSlice, setFileLoading, setPreUploadFilesList, setFilesList } =
	fileUploadSlice.actions;

export default fileUploadSlice.reducer;

export const fileLoadingSelector = state => state.fileUpload.fileLoading;
export const filesListSelector = state => state.fileUpload.filesList;
export const documentsListSelector = createSelector(filesListSelector, filesList =>
	filesList.filter(f => f.document_type === 'document')
);
export const documentsTotalSelector = state => documentsListSelector(state).length;

export const mediaListSelector = createSelector(filesListSelector, filesList =>
	filesList.filter(f => f.document_type === 'media')
);
export const mediaTotalSelector = state => mediaListSelector(state).length;

export const flightLogListSelector = createSelector(filesListSelector, filesList =>
	filesList.filter(f => f.document_type === 'flight_log')
);
export const flightLogTotalSelector = state => flightLogListSelector(state).length;

export const preUploadFilesListSelector = state => state.fileUpload.preUploadFilesList;
export const preUploadDocumentsSelector = createSelector(
	preUploadFilesListSelector,
	preUploadFilesList => preUploadFilesList.filter(f => f.document_type === 'document')
);
export const preUploadMediaSelector = createSelector(
	preUploadFilesListSelector,
	preUploadFilesList => preUploadFilesList.filter(f => f.document_type === 'media')
);
