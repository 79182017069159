export const SEARCH_FILTERS = 'searchFilters';
export const SELECTED_MAP_LAYERS = 'selectedMapLayers';
export const SELECTED_MAP_SOURCES = 'selectedMapSources';
export const SELECTED_MAP_STYLE = 'selectedMapStyle';
export const RECENT_SEARCH = 'recentSearch';
export const LS_LAST_UPDATED_VERSION = 'lsLastUpdatedVersion';
export const TOKEN = 'token';
export const CURRENT_ACCOUNT_ID = 'currentAccountId';
export const COGNITO_CONFIG = 'cognitoConfig';
export const REFRESH_TOKEN = 'refreshToken';
export const TABLE_SETTINGS = 'tableSettings';
export const INTENDED_LOCATION = 'intendedLocation';
export const SELECTED_LANGUAGE = 'selectedLanguage';
export const DETECTED_LANGUAGE = 'detectedLanguage';

export const SELECTED_MAPBOX_STYLE = 'selectedMapboxStyle';
export const SELECTED_SEARCH_TYPE = 'selectedSearchType';
export const LIVE_TRAFFIC_COLUMNS = 'liveTrafficColumns';
export const SGI_WAIVER_OPERATOR_ADDRESS = 'sgiWaiverOperatorAddress';

// --- currently unused ----
// export const USER_ACCOUNTS = 'accounts';
// export const USER_DATA = 'userData';
// export const USER_ROLE = 'userRole';
// export const CURRENT_ACCOUNT = 'currentAccount';
