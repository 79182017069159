import { Route, Routes } from 'react-router-dom';
import GA from 'services/GoogleAnalytics';
import { useSelector } from 'react-redux';
import {
	currentAccountIdSelector,
	currentUserIdSelector,
	tokenSelector,
	userAccountsSelector
} from 'reducers/userAuthSlice';
import ErrorBoundary from 'components/elements/ErrorBoundary';

import { lazy, Suspense } from 'react';
import Logout from 'components/authentication/Logout'; //This can not be lazy loaded or logout will error
const AirSpaceContainer = lazy(() => import('components/airSpace/AirSpaceContainer'));
const ManageContainer = lazy(() => import('components/manage/ManageContainer'));
const MobileManageContainer = lazy(() => import('components/manage/MobileManageContainer'));
const UnauthorizedRoute = lazy(() => import('components/authentication/UnauthorizedRoute'));
const ReportsContainer = lazy(() => import('components/reports/ReportsContainer2'));
const AccountSettingsContainer = lazy(
	() => import('components/accountSettings/AccountSettingsContainer')
);
const StreamingContainer = lazy(() => import('components/streaming/StreamingContainer'));
const FlightViewer = lazy(() => import('components/flightPathViewer/FlightPathViewer'));
const ResetPassword = lazy(() => import('components/authentication/ResetPassword'));
const SignIn = lazy(() => import('components/authentication/SignIn'));
const SignUp = lazy(() => import('components/authentication/SignUp'));
const ConfirmSignUp = lazy(() => import('components/authentication/ConfirmSignUp'));
const RedeemInvite = lazy(() => import('components/authentication/RedeemInvite'));
const App = lazy(() => import('components/App'));
const PostCognito = lazy(() => import('components/authentication/PostCognito'));
const AccountContainer = lazy(() => import('components/AccountContainer'));
const DeleteAccount = lazy(() => import('components/authentication/DeleteAccount'));
const AccountPicker = lazy(() => import('components/authentication/AccountPicker'));
const RequireAuthentication = lazy(() => import('components/elements/RequireAuthentication'));
const NoAccounts = lazy(() => import('components/authentication/NoAccounts'));
const ResponsiveContainer = lazy(() => import('components/elements/ResponsiveContainer'));
const MobileAccountContainer = lazy(() => import('components/MobileAccountContainer'));
const SnackbarAlerts = lazy(() => import('components/elements/SnackbarAlerts'));

const AppRoutes = () => {
	const currentAccountId = useSelector(currentAccountIdSelector);
	const token = useSelector(tokenSelector);
	const userAccounts = useSelector(userAccountsSelector);
	const userId = useSelector(currentUserIdSelector);

	return (
		<ErrorBoundary>
			{GA.init({ options: { debug: false }, currentAccountId, token, userAccounts, userId }) && (
				<GA.RouteTracker />
			)}
			<Routes>
				<Route
					path='/signup'
					element={
						<Suspense fallback={<div />}>
							<SignUp />
						</Suspense>
					}
				/>
				<Route
					path='/confirm-signup'
					element={
						<Suspense fallback={<div />}>
							<ConfirmSignUp />
						</Suspense>
					}
				/>
				<Route
					path='/login'
					element={
						<Suspense fallback={<div />}>
							<SignIn />
						</Suspense>
					}
				/>
				<Route
					path='/reset-password'
					element={
						<Suspense fallback={<div />}>
							<ResetPassword />
						</Suspense>
					}
				/>
				<Route
					path='/redeem/:hash'
					element={
						<Suspense fallback={<div />}>
							<RedeemInvite />
						</Suspense>
					}
				/>
				<Route
					path='share-streaming/:shareToken/:shareId'
					element={
						<Suspense fallback={<div />}>
							<StreamingContainer />
						</Suspense>
					}
				/>
				<Route
					path='/'
					element={
						<Suspense fallback={<div />}>
							<App />
						</Suspense>
					}
				>
					<Route
						path='post-login-flow'
						element={
							<Suspense fallback={<div />}>
								<PostCognito />
							</Suspense>
						}
					/>
					<Route
						path='logout'
						element={
							<Suspense fallback={<div />}>
								<Logout />
							</Suspense>
						}
					/>
					<Route
						path='unauthorized'
						element={
							<Suspense fallback={<div />}>
								<UnauthorizedRoute />
							</Suspense>
						}
					/>
					<Route
						path='account-picker'
						element={
							<Suspense fallback={<div />}>
								<RequireAuthentication>
									<AccountPicker />
								</RequireAuthentication>
							</Suspense>
						}
					/>
					<Route
						path='no-accounts'
						element={
							<Suspense fallback={<div />}>
								<RequireAuthentication>
									<NoAccounts />
								</RequireAuthentication>
							</Suspense>
						}
					/>
					<Route
						path='account/:id/delete'
						element={
							<Suspense fallback={<div />}>
								<DeleteAccount />
							</Suspense>
						}
					/>
					<Route
						path='account/:id/*'
						element={
							<Suspense fallback={<div />}>
								<RequireAuthentication>
									<ResponsiveContainer Mobile={MobileAccountContainer} Desktop={AccountContainer} />
								</RequireAuthentication>
							</Suspense>
						}
					>
						<Route
							path='airspace'
							element={
								<Suspense fallback={<div />}>
									<AirSpaceContainer />
								</Suspense>
							}
						/>
						<Route
							path='streaming'
							element={
								<Suspense fallback={<div />}>
									<StreamingContainer />
								</Suspense>
							}
						/>
						<Route
							path='manage/*'
							element={
								<Suspense fallback={<div />}>
									<ResponsiveContainer Mobile={MobileManageContainer} Desktop={ManageContainer} />
								</Suspense>
							}
						/>
						<Route
							path='flight-viewer/:flightId'
							element={
								<Suspense fallback={<div />}>
									<FlightViewer />
								</Suspense>
							}
						/>
						<Route
							path='reports/*'
							element={
								<Suspense fallback={<div />}>
									<ReportsContainer />
								</Suspense>
							}
						/>
						<Route
							path='settings'
							element={
								<Suspense fallback={<div />}>
									<AccountSettingsContainer />
								</Suspense>
							}
						/>
					</Route>
				</Route>
			</Routes>
			<Suspense fallback={<div />}>
				<SnackbarAlerts />
			</Suspense>
		</ErrorBoundary>
	);
};

export default AppRoutes;
