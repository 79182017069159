import { useSelector } from 'react-redux';
import { localeSelector } from 'reducers/userAuthSlice';
import { useEffect } from 'react';
import i18next from 'i18next';
import { SELECTED_LANGUAGE } from 'constants/localStorageConstants';
import dayjs from 'utils/customDayJS';

const useLocaleConnector = () => {
	const locale = useSelector(localeSelector);

	useEffect(() => {
		if (!locale) {
			// we don't have a language configured on the server
			// do we need to change language to detected ?
			return;
		}

		//Set selected language based on user local and account locale
		void i18next.changeLanguage(locale).then(() => {
			dayjs.locale(locale);

			localStorage.setItem(SELECTED_LANGUAGE, locale);
		});
	}, [locale]);
};

export const LocaleConnector = () => {
	useLocaleConnector();
	return null;
};
